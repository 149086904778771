import { createSlice } from "@reduxjs/toolkit";

interface NavMenu {
    open: boolean;
}

const initialState: NavMenu = {
    open: false,
};

const Slice = createSlice({
    name: "value",
    initialState,
    reducers: {
        setIsNavOpen: (state, action) => {
            state.open = action.payload;
        }
    },
});

export const { setIsNavOpen } = Slice.actions;
export default Slice.reducer;
export type { NavMenu };
