import React from "react";
import { downloadResume, importAll, toLink } from "../../utils/general";
import { GrLinkedin, GrGithub, GrMail } from "react-icons/gr";
import { Link } from "react-router-dom";

const Footer = () => {
    const projectsObject = importAll(
        require.context("../../pages/projects", true, /\.tsx/)
    );
    const experienceObject = importAll(
        require.context("../../pages/experience", true, /\.tsx/)
    );

    const projects = Object.keys(projectsObject).map((project) => {
        return projectsObject[project].thumbnail;
    });

    const experience = Object.keys(experienceObject).map((exp) => {
        return experienceObject[exp].thumbnail;
    });

    const sections = [
        {
            title: "Home",
            link: "/",
        },
        {
            title: "Experience",
            link: "/",
            sublinks: experience.map((exp: { company: any; link: any }) => {
                return {
                    title: exp.company,
                    link: toLink(exp.link),
                };
            }),
        },
        {
            title: "Projects",
            link: "/",
            sublinks: projects.map((project: { title: any; link: any }) => {
                return {
                    title: project.title,
                    link: toLink(project.link),
                };
            }),
        },
        {
            title: "About",
            link: "/about",
        },
    ];

    const links = [
        {
            title: "LinkedIn",
            link: "https://www.linkedin.com/in/benjamin-kamin/",
            icon: <GrLinkedin />,
        },
        {
            title: "GitHub",
            link: "https://github.com/BenKamin03",
            icon: <GrGithub />,
        },
        {
            title: "Email",
            link: "mailto:benjamin.kamin.81@gmail.com",
            icon: <GrMail />,
        },
    ];

    return (
        <div className="grid grid-cols-3 py-4 mx-6 px-6 border-t-[1px] border-primary">
            <div className="">
                <h1 className="text-2xl">Navigation</h1>
                <ul>
                    {sections.map((section) => {
                        return (
                            <li key={section.title} className="relative">
                                {window.location.pathname === "/" ? (
                                    <button
                                        onClick={() => {
                                            let element =
                                                document.getElementById(
                                                    toLink(section.title)
                                                );
                                            if (element) {
                                                element.scrollIntoView({
                                                    behavior: "smooth",
                                                });
                                            } else if (section.sublinks) {
                                                element =
                                                    document.getElementById(
                                                        toLink(
                                                            section.sublinks[0]
                                                                .title
                                                        )
                                                    );
                                                if (element) {
                                                    element.scrollIntoView({
                                                        behavior: "smooth",
                                                        block: "center",
                                                    });
                                                }
                                            }
                                        }}
                                    >
                                        {section.title}
                                    </button>
                                ) : (
                                    <Link
                                        to={section.link
                                            .replace("/", "")
                                            .replace("/", "")}
                                    >
                                        {section.title}
                                    </Link>
                                )}
                                {section.sublinks && (
                                    <ul className="ml-4 bg-background p-2 z-10">
                                        {section.sublinks.map(
                                            (
                                                sublink: {
                                                    title: string;
                                                    link: string;
                                                },
                                                index
                                            ) => {
                                                return (
                                                    <li
                                                        key={sublink.title}
                                                        className="transition-opacity duration-300 ease-in-out"
                                                    >
                                                        {window.location
                                                            .pathname !==
                                                        sublink.link ? (
                                                            <Link
                                                                className="text-nowrap"
                                                                to={
                                                                    sublink.link
                                                                }
                                                            >
                                                                {sublink.title}
                                                            </Link>
                                                        ) : (
                                                            <p className="text-nowrap">
                                                                {sublink.title}
                                                            </p>
                                                        )}
                                                    </li>
                                                );
                                            }
                                        )}
                                    </ul>
                                )}
                            </li>
                        );
                    })}
                </ul>
            </div>
            <div className="text-center">
                <h1 className="text-2xl">Socials</h1>
                <ul className="flex justify-center items-center space-x-4 mt-2">
                    {links.map((link) => {
                        return (
                            <li key={link.title}>
                                <Link
                                    className="text-2xl"
                                    to={link.link}
                                    target="_blank"
                                >
                                    {link.icon}
                                </Link>
                            </li>
                        );
                    })}
                </ul>
            </div>
            <div className="text-right">
                <button className="text-2xl" onClick={downloadResume}>
                    Resume
                </button>
            </div>
        </div>
    );
};

export default Footer;
