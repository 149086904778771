import { useScroll, useTransform, motion } from "framer-motion";
import React from "react";
import { IoChevronDownSharp } from "react-icons/io5";
import { SiC } from "react-icons/si";

const CServer = () => {
    const { scrollYProgress } = useScroll();

    const opacity = useTransform(scrollYProgress, [0, 0.05], [1, 0]);

    const languages: { [key: string]: { [key: string]: JSX.Element } } = {
        Backend: {
            C: <SiC />,
        },
    };
    return (
        <div className="flex justify-center items-center flex-col mb-24">
            <div className="w-full h-screen flex justify-center items-center">
                <div className="w-auto divide-y-[1px] divide-primary flex flex-col justify-center items-center">
                    <h1 className="text-4xl px-4 text-nowrap w-min">
                        Web Server in C
                    </h1>
                    <div className="flex flex-center items-center gap-4 w-full pt-1">
                        <div className="w-full h-full flex justify-center items-center gap-4">
                            {Object.keys(languages).map((area: string) => (
                                <React.Fragment key={area}>
                                    {Object.entries(languages[area]).map(
                                        ([language, icon]) => (
                                            <React.Fragment key={language}>
                                                {icon}
                                            </React.Fragment>
                                        )
                                    )}
                                </React.Fragment>
                            ))}
                        </div>
                    </div>
                </div>
                <motion.p
                    style={{ opacity }}
                    className="flex gap-2 group justify-center items-center text-primary z-10 fixed bottom-1"
                >
                    Scroll Down <IoChevronDownSharp />
                </motion.p>
            </div>

            <div className="w-full flex flex-col items-center">
                <h2 className="text-2xl mb-4">Project Overview</h2>
                <p className="text-center max-w-2xl mb-8">
                    This project is a simple HTTP server written in C that
                    handles basic GET requests and serves files to clients. It
                    is designed to create a socket, bind it to a specified port,
                    and listen for incoming connections. The server uses the
                    poll function to handle multiple clients simultaneously. It
                    processes requests by parsing the requested URL, fetching
                    the corresponding file from the server’s directory, and
                    sending it back to the client with appropriate HTTP headers.
                </p>
                <ul className="text-left max-w-2xl">
                    <li>
                        &emsp;&emsp;<strong>Socket Creation and Binding:</strong> The
                        server creates a socket, binds it to a specified port,
                        and listens for incoming connections.
                    </li>
                    <li>
                        &emsp;&emsp;<strong>Polling:</strong> The server uses the poll
                        function to handle multiple clients simultaneously.
                    </li>
                    <li>
                        &emsp;&emsp;<strong>Request Processing:</strong> The server
                        processes requests by parsing the requested URL,
                        fetching the corresponding file from the server’s
                        directory, and sending it back to the client with
                        appropriate HTTP headers.
                    </li>
                    <li>
                        &emsp;&emsp;<strong>Connection Handling:</strong> Each client
                        connection is handled in a separate thread to allow for
                        multiple clients to connect to the server
                        simultaneously.
                    </li>
                    <li>
                        &emsp;&emsp;<strong>File Serving:</strong> The server serves
                        files to clients by reading the requested file from the
                        server’s directory and sending it back to the client
                        with appropriate HTTP headers.
                    </li>
                    <li>
                    &emsp;&emsp;<strong>Port Configuration:</strong> The server
                        allows for the configuration of the port number on which
                        it listens for incoming connections.
                    </li>
                </ul>
            </div>
        </div>
    );
};

export default CServer;
export const thumbnail = {
    url: "/assets/images/projects/server.png",
    title: "Server in C",
    link: "/c-server",
};
