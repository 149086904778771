import { motion, useScroll, useTransform } from "framer-motion";
import React, { useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import { toLink } from "../../utils/general";

interface VerticalScrollCarouselProps {
    cards: Card[];
    label: string;
    sublabel?: string;
}

interface Card {
    img: string;
    company: string;
    title: string;
    startDate: string;
    endDate?: string;
    current?: boolean;
    link: string;
}

const VerticalScrollCarousel: React.FC<VerticalScrollCarouselProps> = ({
    cards,
    label,
    sublabel,
}) => {
    const sectionRef = useRef<HTMLDivElement | null>(null);
    const [width, setWidth] = React.useState(window.innerWidth);
    const { scrollYProgress } = useScroll({
        target: sectionRef,
        offset: ["start center", "end center"],
    });
    const x = useTransform(
        scrollYProgress,
        [0.04999999, 0.05, 0.999999, 1],
        ["150%", "0%", "0%", "150%"]
    );
    const labelX = useTransform(
        scrollYProgress,
        [0.04999999, 0.05, 0.99999, 1],
        ["-225%", width > 640 ? "0%" : "50%", width > 640 ? "0%" : "50%", "-225%"]
    );
    const opacity = useTransform(
        scrollYProgress,
        [0.04999999, 0.05, 0.99999, 1],
        [0, 1, 1, 0]
    );

    const getClosestCard = () => {
        return Math.floor(scrollYProgress.get() * cards.length);
    };

    const [currentCard, setCurrentCard] = React.useState(getClosestCard);

    useEffect(() => {
        const handleScroll = () => {
            setCurrentCard(getClosestCard());
        };

        window.addEventListener("scroll", handleScroll);

        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [sectionRef]);

    useEffect(() => {
        const handleResize = () => {
            setWidth(window.innerWidth);
        };

        window.addEventListener("resize", handleResize);

        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []);

    return (
        <>
            <section
                ref={sectionRef}
                className="flex justify-center items-center flex-col gap-y-4 sticky top-0"
                style={{ height: `${cards.length * 60}vmin` }}
            >
                <motion.div className="fixed top-1/2 -translate-y-1/2 -left-4 sm:left-8 -rotate-90 sm:rotate-0">
                    <div className="h-full flex items-center flex-col gap-4 sm:gap-0 sm:flex-col -translate-y-1/2 sm:translate-y-0">
                        <motion.h1
                            style={
                                width > 640
                                    ? { x: labelX, opacity }
                                    : { y: labelX, opacity }
                            }
                            className="text-4xl -rotate-90 sm:rotate-0 -translate-x-[2.25rem] sm:translate-x-0 transition-all duration-300"
                        >
                            {label}
                        </motion.h1>
                        {sublabel && (
                            <motion.p
                                style={
                                    width > 640
                                        ? { x: labelX, opacity }
                                        : { y: labelX, opacity }
                                }
                                className="transition-all duration-300 delay-100 text-xs sm:text-base -rotate-90 sm:rotate-0 -translate-x-[2.25rem] sm:translate-x-0 w-full"
                            >
                                {sublabel}
                            </motion.p>
                        )}
                    </div>
                </motion.div>
                <div className="fixed top-1/2 -translate-y-1/2 right-2 sm:right-8 flex flex-col">
                    {cards.map((card, index) => (
                        <motion.button
                            style={{
                                x,
                                transition: `transform 0.3s ${index * 0.1}s`,
                            }}
                            className="w-16 h-20 z-10 cursor-pointer"
                            key={index}
                            onClick={() => {
                                const element = document.getElementById(
                                    toLink(card.company)
                                );
                                if (element) {
                                    element.scrollIntoView({
                                        behavior: "smooth",
                                        block: "center",
                                    });
                                }
                            }}
                        >
                            <div className="p-2 w-full h-full relative">
                                <img
                                    src={card.img}
                                    alt={card.company}
                                    className="bg-neutral-200 w-full h-full object-cover"
                                />
                            </div>
                        </motion.button>
                    ))}

                    <motion.div
                        className="fixed w-16 h-20 translate-y-"
                        style={{
                            top: `calc(${currentCard ?? 0} * calc(5rem))`,
                            x,
                            transition: `all 0.3s ${(currentCard || 0) * 0.1}s`,
                        }}
                    >
                        <div className="absolute top-0 left-0 border-2 w-[12.5%] aspect-square border-transparent border-t-primary border-l-primary pointer-events-none" />
                        <div className="absolute top-0 right-0 border-2 w-[12.5%] aspect-square border-transparent border-t-primary border-r-primary pointer-events-none" />
                        <div className="absolute bottom-0 left-0 border-2 w-[12.5%] aspect-square border-transparent border-b-primary border-l-primary pointer-events-none" />
                        <div className="absolute bottom-0 right-0 border-2 w-[12.5%] aspect-square border-transparent border-b-primary border-r-primary pointer-events-none" />
                    </motion.div>
                </div>
                <div className="h-full w-full flex flex-col overflow-hidden justify-center items-center gap-y-4">
                    {cards.map((card, index) => {
                        return (
                            <VerticalScrollCard
                                card={card}
                                key={index}
                                currentCardIndex={index === currentCard}
                            />
                        );
                    })}
                </div>
            </section>
        </>
    );
};

const VerticalScrollCard = ({
    card,
    currentCardIndex,
}: {
    card: Card;
    currentCardIndex: boolean;
}) => {
    const cardRef = useRef<HTMLDivElement | null>(null);

    const { scrollYProgress } = useScroll({
        target: cardRef,
        offset: ["start center", "end center"],
    });

    const scale = useTransform(
        scrollYProgress,
        [0, 0.5, 1, 1.1],
        [0.8, 1, 1, 0.8]
    );

    return (
        <motion.div
            ref={cardRef}
            id={toLink(card.company)}
            className="relative h-[60vmin] w-[50vmin] flex justify-center group z-20"
            style={{ scale }}
        >
            <Link
                to={toLink(card.link)}
                className="w-full h-full flex justify-center"
            >
                <img
                    src={card.img}
                    alt={card.company}
                    className={`h-full w-full object-cover bg-neutral-200 transition-transform duration-300`}
                />
                <div
                    className={`absolute right-0 top-0 origin-right -rotate-90 w-[58vmin] flex justify-between -translate-y-1/2 translate-x-4 text-xs sm:text-xl uppercase ${
                        currentCardIndex ? "translate-x-5" : ""
                    } transition-all duration-300`}
                >
                    <p>{card.title.replace("Software Engineering", "SWE")}</p>
                    <p>
                        {card.startDate}-
                        {card.current ? "Present" : card.endDate}
                    </p>
                </div>
            </Link>
        </motion.div>
    );
};

export default VerticalScrollCarousel;
