import VerticalScrollCarousel from "../../../../components/carousels/VerticalScrollCarousel";
import { importAll } from "../../../../utils/general";

const Experience = () => {
    const experiencesObject = importAll(
        require.context("../../../../pages/experience", false, /\.tsx$/)
    );

    const experiences = Object.keys(experiencesObject).map((experience) => {
        const Experience = experiencesObject[experience];
        return {
            ...Experience.thumbnail,
            id: experience,
            company: Experience.thumbnail.company,
        };
    });

    const today = new Date();
    const totalExperience = experiences.reduce((acc, exp) => {
        const [endMonth, endYear] = exp.current
            ? [today.getMonth() + 1, today.getFullYear()]
            : exp.endDate.split("-").map(Number);
        const [startMonth, startYear] = exp.startDate.split("-").map(Number);

        const endDate = new Date(endYear, endMonth - 1);
        const startDate = new Date(startYear, startMonth - 1);

        const monthsDiff =
            (endDate.getFullYear() - startDate.getFullYear()) * 12 +
            (endDate.getMonth() - startDate.getMonth());

        return acc + monthsDiff;
    }, 0);

    const [years, months] = [
        Math.floor(totalExperience / 12),
        totalExperience % 12,
    ];

    const experienceString = `${
        years > 0 ? `${years} Year${years > 1 ? "s" : ""}, ` : ""
    }${months} Month${months > 1 ? "s" : ""}`;

    return (
        <div className="relative mt-2 w-full h-full">
            <VerticalScrollCarousel
                cards={experiences}
                label={"Experience"}
                sublabel={experienceString}
            />
        </div>
    );
};

export default Experience;
