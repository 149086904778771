import { useScroll, useTransform, motion } from "framer-motion";
import React from "react";
import { FaReact } from "react-icons/fa";
import { IoChevronDownSharp } from "react-icons/io5";
import {
    SiJavascript,
    SiTailwindcss,
    SiExpress,
    SiMongodb,
} from "react-icons/si";

const SocialMedia = () => {
    const { scrollYProgress } = useScroll();

    const opacity = useTransform(scrollYProgress, [0, 0.05], [1, 0]);

    const languages: { [key: string]: { [key: string]: JSX.Element } } = {
        Frontend: {
            JavaScript: <SiJavascript />,
            React: <FaReact />,
            TailwindCSS: <SiTailwindcss />,
        },
        Backend: {
            Express: <SiExpress />,
        },
        Database: {
            MongoDB: <SiMongodb />,
        },
    };

    return (
        <div className="flex justify-center items-center flex-col mb-24">
            <div className="w-full h-screen flex justify-center items-center">
                <div className="w-auto divide-y-[1px] divide-primary flex flex-col justify-center items-center">
                    <h1 className="text-4xl px-4 text-nowrap w-min">
                        Recipe Social Media
                    </h1>
                    <div className="flex flex-center items-center gap-4 w-full pt-1">
                        <div className="w-full h-full flex justify-center items-center gap-4">
                            {Object.keys(languages).map((area: string) => (
                                <React.Fragment key={area}>
                                    {Object.entries(languages[area]).map(
                                        ([language, icon]) => (
                                            <React.Fragment key={language}>
                                                {icon}
                                            </React.Fragment>
                                        )
                                    )}
                                </React.Fragment>
                            ))}
                        </div>
                    </div>
                </div>
                <motion.p
                    style={{ opacity }}
                    className="flex gap-2 group justify-center items-center text-primary z-10 fixed bottom-1"
                >
                    Scroll Down <IoChevronDownSharp />
                </motion.p>
            </div>

            <img
                src="/assets/images/projects/recipe_hub_1.png"
                alt="Recipe Social Media"
                className="w-3/4 h-auto"
            />

            <p className="mt-4">Recipe Social Media</p>
        </div>
    );
};

export default SocialMedia;
export const thumbnail = {
    url: "/assets/images/projects/recipe_hub_1.png",
    title: "Recipe Social Media",
    link: "/recipe-social-media",
};
