import React from "react";

const About = () => {
    return (
        <div className="min-h-screen grid grid-cols-2 mb-12">
            <div className="h-full flex justify-center items-center flex-col mx-2 sm:mx-12 space-y-4">
                <p className="w-full">
                    I am a 21 year old Full Stack Software Engineer based in the
                    San Francisco Bay Area. I am currently a 4th year Computer
                    Science major at the University of San Francisco, holding a
                    3.9 GPA. I am passionate about building high-quality
                    software and web applications that are user-friendly,
                    accessible and unique. I have experience working with a
                    variety of technologies.
                </p>
                <p>
                    Outside of Software, I am also interested in soccer, music
                    and video games. I've been playing soccer since I was 4 and
                    I've been playing video games since I was 8. I love
                    competition and I love to win. I worked hard enough at a
                    video game called Rocket League to where I competed to be in
                    the top 48 teams in North America for several years. I love
                    learning and I love improving.
                </p>
            </div>
            <img
                src="/assets/images/portrait/portrait.jpg"
                alt="Portrait"
                className="w-full h-full object-cover"
            />
        </div>
    );
};

export default About;
