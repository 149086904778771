import { IoMenuSharp } from "react-icons/io5";
import { MdOutlineClose } from "react-icons/md";
import NavMenu from "./NavMenu";
import { LiaDownloadSolid } from "react-icons/lia";
import { downloadResume } from "../../utils/general";
import { useSelector } from "react-redux";
import { setIsNavOpen } from "../../utils/state/slices/navmenu";
import { RootState } from "../../utils/state/store";
import { useDispatch } from "react-redux";
import HeaderName from "./HeaderName";

const Navbar = () => {
    const isNavOpen = useSelector((state: RootState) => state.navmenu.open);
    const dispatch = useDispatch();

    const closeMenu = () => {
        dispatch(setIsNavOpen(false));
    };

    const toggleMenu = () => {
        dispatch(setIsNavOpen(!isNavOpen));
    };

    return (
        <>
            <NavMenu open={isNavOpen} onClose={() => closeMenu()} />
            <div
                className={`fixed top-0 left-0 right-0 bg-gradient-to-t from-transparent to-background z-30 pointer-events-none`}
            >
                <div className="h-16 flex items-center justify-center px-8 relative">
                    <button
                        onClick={downloadResume}
                        className={`top-8 right-8 fixed -translate-x-1/2 -translate-y-1/2 w-fit h-fit group transition-all text-primary pointer-events-auto`}
                    >
                        <LiaDownloadSolid className="group-active:translate-y-1 transition-all" />
                    </button>
                    <HeaderName />
                    <button
                        onClick={() => toggleMenu()}
                        style={{ zIndex: 9999 }}
                        className={`top-8 left-8 fixed -translate-x-1/2 -translate-y-1/2 w-fit h-fit text-primary pointer-events-auto`}
                    >
                        <div
                            className={`transition-all ${
                                isNavOpen ? "rotate-0" : "rotate-180"
                            }`}
                        >
                            {isNavOpen ? <MdOutlineClose /> : <IoMenuSharp />}
                        </div>
                    </button>
                </div>
            </div>
        </>
    );
};

export default Navbar;
