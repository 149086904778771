import Navbar from "./components/navbar/Navbar";
import { Route, Routes, Navigate, useLocation, Outlet } from "react-router-dom";
import { importAll, toLink } from "./utils/general";
import Home from "./pages/home/Home";
import About from "./pages/about/About";
import Footer from "./components/footer/Footer";
import { AnimatePresence } from "framer-motion";
import PageTransition from "./components/animation/PageTransition";
import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { addPage, setIsFirstRender } from "./utils/state/slices/renders";
import { RootState } from "./utils/state/store";
import { SpeedInsights } from "@vercel/speed-insights/react";
import { Analytics } from "@vercel/analytics/react";

function App() {
    const { numPages } = useSelector((state: RootState) => state.renders);
    const dispatch = useDispatch();
    const { firstRender } = useSelector((state: RootState) => state.renders);

    const pages = {
        ...importAll(require.context("./pages/experience", true, /\.tsx/)),
        ...importAll(require.context("./pages/projects", true, /\.tsx/)),
    };

    const location = useLocation();

    const HomePage = PageTransition(Home);
    const AboutPage = PageTransition(About);

    useEffect(() => {
        if (!firstRender)
            setTimeout(() => {
                window.scrollTo(0, 0);
            }, 300);

        if (numPages !== 0) {
            dispatch(setIsFirstRender(false));
        } else {
            dispatch(addPage());
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [location.pathname]);

    useEffect(() => {
        if (!firstRender)
            setTimeout(() => {
                window.scrollTo(0, 0);
            }, 300);
    }, [firstRender]);

    return (
        <>
            <SpeedInsights />
            <Analytics />
            <AnimatePresence mode={"wait"}>
                <Routes location={location} key={location.pathname}>
                    <Route path="/" element={<Layout />}>
                        {Object.keys(pages).map((page) => {
                            const Page = PageTransition(pages[page].default);
                            return (
                                <Route
                                    key={page}
                                    path={toLink(pages[page].thumbnail.link)}
                                    element={<Page />}
                                />
                            );
                        })}

                        <Route path="/about" element={<AboutPage />} />
                        <Route path="/" element={<HomePage />} />

                        <Route path="*" element={<Navigate to="/" replace />} />
                    </Route>
                </Routes>
            </AnimatePresence>
        </>
    );
}

const Layout = () => {
    return (
        <>
            <Navbar />
            <Outlet />
            <Footer />
        </>
    );
};

export default App;
