import React from "react";
import { CgChevronRight } from "react-icons/cg";
import { Link } from "react-router-dom";
import { motion, useInView } from "framer-motion";

const About = () => {
    const ref = React.useRef(null);
    const isInView = useInView(ref, { once: false });

    return (
        <div
            id="about"
            className="min-h-screen relative flex items-center justify-center"
        >
            <motion.div
                ref={ref}
                initial={{ opacity: 0, y: -50 }}
                animate={{ opacity: isInView ? 1 : 0, y: isInView ? 0 : -50 }}
                transition={{ duration: 0.5 }}
                className="relative object-cover w-3/4 max-w-[400px]"
            >
                <img
                    alt="portrait"
                    src="/assets/images/portrait/portrait.jpg"
                    className=""
                />
                <motion.div
                    initial={{ x: "100%" }}
                    animate={{ x: isInView ? "0%" : "100%" }}
                    transition={{ duration: 0.5 }}
                    className="absolute bottom-[10%] left-1/2 w-1/2 bg-background p-4 space-y-2"
                >
                    <Link
                        to={"/about"}
                        className="group flex items-center gap-2 w-full text-left"
                    >
                        Read About Me{" "}
                        <CgChevronRight className="group-hover:animate-bounce-horizontal-right" />
                    </Link>
                </motion.div>

                <button
                    onClick={() =>
                        window.scrollTo({ top: 0, behavior: "smooth" })
                    }
                    className="absolute top-[105%] left-1/2 -translate-x-1/2"
                >
                    To Top
                </button>
            </motion.div>
        </div>
    );
};

export default About;
