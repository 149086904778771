import { createSlice } from "@reduxjs/toolkit";
import { importAll } from "../../general";


interface Pages {
    projects: string[],
    experience: string[]
}

const projects = importAll(
    require.context("../../../pages/projects", true, /\.tsx/)
);

const experience = importAll(
    require.context("../../../pages/experience", true, /\.tsx/)
)

const initialState: Pages = {
    projects: Object.keys(projects).map((key) => projects[key].thumbnail.title),
    experience: Object.keys(experience).map((key) => experience[key].thumbnail.company),
};

const Slice = createSlice({
    name: "value",
    initialState,
    reducers: {},
});

export default Slice.reducer;
export type { Pages };
