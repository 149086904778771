/**
 * This file is responsible for creating the Redux store.
 * 
 * @author Ben Kamin
 * 
 * The store is created using the configureStore function from the @reduxjs/toolkit package.
 * The store is configured with a map of reducers, where the key is the name of the reducer and the value is the reducer function.
 * The importAll function is used to import all files in the slices directory and return them as a map of reducers.
 * The store is exported along with the RootState and AppDispatch types.
 */

import { configureStore } from "@reduxjs/toolkit";

/**
 * ReducerMap is a map of reducers, where the key is the name of the reducer and the value is the reducer function.
 */
interface ReducerMap {
    [key: string]: (state: any, action: any) => any;
}

/**
 * Import all files in the slices directory
 * 
 * @param r The context
 * @returns the controllers
 */
const importAll = (r: __WebpackModuleApi.RequireContext) => {
    const controllers: ReducerMap = {};
    r.keys().forEach((key: string) => {
        const controllerName = key.replace("./", "").replace(".ts", "");
        const controllerModule = r(key).default;

        controllers[controllerName] = controllerModule;
    });
    return controllers;
};

// Import all controllers
export const store = configureStore({
    reducer: {...importAll(require.context("./slices", true, /\.ts$/))},
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
