const toLink = (text: string) => {
    return text.replace(/\s/g, "-").toLowerCase();
}

interface ReducerMap {
    [key: string]: any;
}

const importAll = (r: __WebpackModuleApi.RequireContext) => {
    const controllers: ReducerMap = {};
    r.keys().forEach((key: string) => {
        const controllerName = key.replace("./", "").replace(".tsx", "").replace(".ts", "");
        const controllerModule = r(key);

        controllers[controllerName] = controllerModule;
    });
    return controllers;
};

const downloadResume = () => {
    const link = document.createElement("a");
    link.href = "/assets/resume/Ben Kamin - Resume.pdf";
    link.download = "Ben Kamin - Resume.pdf";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
};

export { toLink, importAll, downloadResume };