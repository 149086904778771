import React from "react";
import { Link, useLocation } from "react-router-dom";

const HeaderName = () => {
    const location = useLocation();

    if (location.pathname === "/") {
        return <></>;
    } else {
        return (
            <Link
                to={"/"}
                className="text-center w-fit uppercase pointer-events-auto"
                style={{ fontSize: "3rem" }}
            >
                Ben<span className="font-sans"> </span>Kamin
            </Link>
        );
    }
};

export default HeaderName;
