import { motion, useTransform, useScroll, MotionValue } from "framer-motion";
import { IoIosLink } from "react-icons/io";
import { useRef } from "react";
import { Link } from "react-router-dom";
import { toLink } from "../../utils/general";

interface HorizontalScrollCarouselProps {
    cards: CardType[];
    label: React.ReactElement;
}

const HorizontalScrollCarousel: React.FC<HorizontalScrollCarouselProps> = ({
    cards,
    label,
}) => {
    const targetRef = useRef<HTMLDivElement | null>(null);
    const { scrollYProgress } = useScroll({
        target: targetRef,
    });

    const x = useTransform(scrollYProgress, [0, 1], ["1%", "-75%"]);

    return (
        <section ref={targetRef} className="relative h-[300vh]">
            <div className="sticky top-0 flex h-screen justify-center items-start overflow-hidden flex-col w-full">
                <h1 className="w-full px-4 mb-2">{label}</h1>
                <motion.div
                    style={{ x, height: "50vmin" }}
                    className="flex gap-4"
                >
                    {cards.map((card, index) => {
                        return (
                            <Card
                                card={card}
                                key={card.id}
                                scrollYProgress={scrollYProgress}
                                index={index}
                                length={cards.length}
                            />
                        );
                    })}
                </motion.div>
            </div>
        </section>
    );
};

const Card = ({
    card,
    scrollYProgress,
    index,
    length,
}: {
    card: CardType;
    scrollYProgress: MotionValue<number>;
    index: number;
    length: number;
}) => {
    const width = useTransform(
        scrollYProgress,
        [(index - 1) / length, index / length, (index + 1) / length],
        ["40vmin", "60vmin", "40vmin"]
    );
    const height = useTransform(
        scrollYProgress,
        [(index - 1) / length, index / length, (index + 1) / length],
        ["40vmin", "60vmin", "40vmin"]
    );

    return (
        <motion.div
            key={card.id}
            id={toLink(card.title)}
            className="group relative bg-neutral-200"
            style={{ width, height }}
        >
            <Link to={toLink(card.link)} className="w-full h-full ">
                <img
                    src={card.url}
                    alt={card.title}
                    className="w-full h-full object-cover"
                />
                <div className="flex gap-2 items-center w-full justify-between">
                    <h1>{card.title}</h1>
                    {card.hosted && <IoIosLink />}
                </div>
            </Link>
        </motion.div>
    );
};

export default HorizontalScrollCarousel;
export type CardType = {
    url: string;
    title: string;
    id: number;
    link: string;
    hosted: boolean;
};
