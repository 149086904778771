import { motion, useScroll, useTransform } from "framer-motion";
import { IoChevronDownSharp } from "react-icons/io5";
import {
    SiCss3,
    SiFastapi,
    SiFlask,
    SiJavascript,
    SiMui,
    SiMysql,
    SiPython,
} from "react-icons/si";
import { Timeline } from "antd";

const Eridan = () => {
    const { scrollYProgress } = useScroll();

    const opacity = useTransform(scrollYProgress, [0, 0.05], [1, 0]);

    const technologies: { [key: string]: { [key: string]: JSX.Element } } = {
        Frontend: {
            JavaScript: <SiJavascript />,
            CSS: <SiCss3 />,
            MUI: <SiMui />,
        },
        Backend: {
            Python: <SiPython />,
            FastAPI: <SiFastapi />,
            AIOMySQL: <SiMysql />,
            Flask: <SiFlask />,
        },
        Database: {
            MySQL: <SiMysql />,
        },
    };

    return (
        <div className="w-full h-full min-h-screen flex items-center justify-center flex-col text-primary mb-24">
            <div className="w-full h-screen flex justify-center items-center">
                <div className="w-auto divide-y-[1px] divide-primary flex flex-col justify-center items-center">
                    <h1 className="text-4xl px-4 text-nowrap w-min">
                        Eridan
                    </h1>
                    <div className="flex flex-center items-center gap-12 w-full">
                        <p className="">SWE Contractor / Intern</p>
                        <p className="">02 2024 - Present</p>
                    </div>
                </div>
                <motion.p
                    style={{ opacity }}
                    className="flex gap-2 group justify-center items-center text-primary z-10 fixed bottom-1"
                >
                    Scroll Down <IoChevronDownSharp />
                </motion.p>
            </div>

            <div className="grid grid-cols-3">
                {Object.keys(technologies).map((area: string) => (
                    <div className="flex flex-col item-center justify-start">
                        <h2 className="text-center text-lg relative px-4 w-fit mx-auto">
                            {area}
                        </h2>
                        {Object.entries(technologies[area]).map(
                            ([name, icon]) => (
                                <div
                                    key={name}
                                    className="flex justify-center flex-col items-center py-2"
                                >
                                    <div className="text-3xl">{icon}</div>
                                    <p className="text-center text-sm">
                                        {name}
                                    </p>
                                </div>
                            )
                        )}
                    </div>
                ))}
            </div>

            <div
                className="md:w-1/3 mt-[10vh] flex justify-center items-center text-2xl mx-6 text-center"
                style={{ marginBottom: "calc(10vh + 2rem)" }}
            >
                <p>
                    As both a summer intern and part-time employee, I designed
                    user interfaces for developer tools that improved lab
                    operations and streamlined workflows, while increasing
                    visibility into their actions. I also prototyped for the
                    company website redesign. Currently, I am developing a user
                    interface for the radio.
                </p>
            </div>

            <Timeline style={{ width: "calc(100% - 2rem)" }} mode="left">
                <Timeline.Item
                    dot={<div className="w-2 h-2 rounded-full bg-primary" />}
                    label={
                        <div>
                            <p className="text-lg font-serif">
                                Software Engineering Contractor
                            </p>
                            <p className="text-xs font-serif">
                                08 2024 - Present
                            </p>
                        </div>
                    }
                >
                    <motion.p
                        initial={{ opacity: 0, y: -10 }}
                        whileInView={{
                            opacity: 1,
                            y: 0,
                            transition: {
                                delay: 0,
                                duration: 0.5,
                                once: false,
                            },
                        }}
                        className="w-2/3 md:w-1/2 font-serif"
                    >
                        Currently working on a Configuration Generator & GUI for
                        the Radio. This allows customers to easily configure
                        their radios and assists the lab team in testing and
                        production.
                    </motion.p>
                </Timeline.Item>
                <Timeline.Item
                    dot={<div className="w-2 h-2 rounded-full bg-primary" />}
                    label={
                        <div>
                            <p className="text-lg font-serif">
                                Software Engineering Intern
                            </p>
                            <p className="text-xs font-serif">
                                05 2024 - 08 2024
                            </p>
                        </div>
                    }
                >
                    <motion.p
                        initial={{ opacity: 0, y: -10 }}
                        whileInView={{
                            opacity: 1,
                            y: 0,
                            transition: {
                                delay: 0.1,
                                duration: 0.5,
                                once: false,
                            },
                        }}
                        className="w-2/3 md:w-1/2 font-serif"
                    >
                        Led the development of a new Landing Page for the
                        Developer Tools which displays information about our
                        progress to improve visibility into lab operations.
                        Also, I lead the development of several page's redesigns
                        which included gathering information from multiple
                        different teams and accumulating that into wireframes
                        for iteration, and finally developing the page. I also
                        updated the Front-End's API Structure to be easier to
                        use.
                    </motion.p>
                </Timeline.Item>
                <Timeline.Item
                    dot={<div className="w-2 h-2 rounded-full bg-primary" />}
                    label={
                        <div>
                            <p className="text-lg font-serif">
                                Software Engineering Contractor
                            </p>
                            <p className="text-xs font-serif">
                                02 2024 - 05 2024
                            </p>
                        </div>
                    }
                >
                    <motion.p
                        initial={{ opacity: 0, y: -10 }}
                        whileInView={{
                            opacity: 1,
                            y: 0,
                            transition: {
                                delay: 0.2,
                                duration: 0.5,
                                once: false,
                            },
                        }}
                        className="w-2/3 md:w-1/2 font-serif"
                    >
                        Enhanced MUI's Data Grid to add custom functionality for
                        our lab teams to use. Provided a Web Developer
                        perspective to the Marketing and BD Team for their
                        website revamps, which included prototyping designs and
                        creating iterations off of the designs.
                    </motion.p>
                </Timeline.Item>
            </Timeline>
        </div>
    );
};

export default Eridan;
export const thumbnail = {
    img: "/assets/images/experience/eridan/thumbnail.png",
    company: "Eridan",
    title: "Software Engineering Contractor / Intern",
    startDate: "02-2024",
    current: true,
    link: "/eridan",
};
