import React, { useRef } from "react";

import {
    FaReact,
    FaNodeJs,
    FaPython,
    FaJava,
    FaGitAlt,
    FaWindows,
} from "react-icons/fa";
import {
    SiTypescript,
    SiNextdotjs,
    SiRedux,
    SiMongodb,
    SiExpress,
    SiFastapi,
    SiTailwindcss,
    SiMui,
    SiMysql,
    SiC,
    SiJavascript,
    SiFlask,
    SiJira,
    SiTrello,
    SiMacos,
    SiVercel,
    SiHeroku,
    SiDocker,
    SiMicrosoftazure,
} from "react-icons/si";
import { motion, useScroll, useTransform } from "framer-motion";
import { IoLogoFigma } from "react-icons/io5";

type LanguageCategories = {
    [key: string]: {
        [key: string]: JSX.Element;
    };
};

const Languages = () => {
    const languages: LanguageCategories = {
        Frontend: {
            TypeScript: <SiTypescript />,
            JavaScript: <SiJavascript />,
            React: <FaReact />,
            NextJS: <SiNextdotjs />,
            Redux: <SiRedux />,
            TailwindCSS: <SiTailwindcss />,
            MaterialUI: <SiMui />,
        },
        Backend: {
            Python: <FaPython />,
            C: <SiC />,
            Java: <FaJava />,
            TypeScript: <SiTypescript />,
            JavaScript: <SiJavascript />,
            NodeJS: <FaNodeJs />,
            Express: <SiExpress />,
            FastAPI: <SiFastapi />,
            Flask: <SiFlask />,
        },
        Database: {
            MySQL: <SiMysql />,
            MongoDB: <SiMongodb />,
        },
        Tools: {
            Figma: <IoLogoFigma />,
            Git: <FaGitAlt />,
            Jira: <SiJira />,
            Trello: <SiTrello />,
            Vercel: <SiVercel />,
            Heroku: <SiHeroku />,
            Docker: <SiDocker />,
            Azure: <SiMicrosoftazure />,
        },
        OS: {
            Windows: <FaWindows />,
            Mac: <SiMacos />,
        },
    };

    const mobile: { [key in keyof typeof languages]: string } = {
        Frontend: "FE",
        Backend: "BE",
        Database: "DB",
        Tools: "Tools",
        OS: "OS",
    };

    const containerVariants = {
        hidden: { opacity: 0 },
        visible: {
            opacity: 1,
            transition: {
                staggerChildren: 0.05,
            },
        },
    };

    const itemVariants = {
        hidden: { opacity: 0, y: 20 },
        visible: { opacity: 1, y: 0 },
    };

    const titleRef = useRef<HTMLHeadingElement | null>(null);

    const { scrollYProgress } = useScroll({
        target: titleRef,
        offset: ["center center", "center end"],
    });

    const width = useTransform(scrollYProgress, [0, 1], ["100%", "0%"]);

    return (
        <div className="min-h-[50vh] mt-[20vh] flex justify-center items-center flex-col">
            <div
                ref={titleRef}
                className="w-full flex justify-center items-center"
            >
                <motion.div
                    className="grid grid-cols-5 gap-2 sm:gap-12 mx-4"
                    variants={containerVariants}
                    initial="hidden"
                    whileInView="visible"
                    viewport={{ once: false, amount: 0.5 }}
                >
                    {Object.keys(languages).map((area) => (
                        <div className="flex flex-col item-center justify-start">
                            <h2 className="text-center text-lg relative px-4 w-fit mx-auto ">
                                <span className="hidden sm:block">{area}</span>
                                <span className="visible sm:hidden">{mobile[area]}</span>
                                <motion.div
                                    className="w-full h-0.5 bg-primary absolute top-[100%] left-0"
                                    style={{ width }}
                                />
                            </h2>
                            {Object.entries(languages[area]).map(
                                ([name, icon]) => (
                                    <motion.div
                                        key={name}
                                        className="flex justify-center flex-col items-center py-2"
                                        variants={itemVariants}
                                        exit={{
                                            opacity: 0,
                                            y: -20,
                                            transition: { duration: 0.5 },
                                        }}
                                    >
                                        <div className="text-2xl w-full flex justify-center">
                                            {icon}
                                        </div>
                                        <p className="text-sm w-full text-center">
                                            {name}
                                        </p>
                                    </motion.div>
                                )
                            )}
                        </div>
                    ))}
                </motion.div>
            </div>
        </div>
    );
};

export default Languages;
