import { createSlice } from "@reduxjs/toolkit";

interface PageTransition {
    firstRenderTransition: boolean;
}

const initialState: PageTransition = {
    firstRenderTransition: true,
};

const Slice = createSlice({
    name: "value",
    initialState,
    reducers: {
        setIsFirstRenderTransition: (state, action) => {
            state.firstRenderTransition = action.payload;
        },
    },
});

export const { setIsFirstRenderTransition } = Slice.actions;
export default Slice.reducer;
export type { PageTransition };
