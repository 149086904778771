import { motion, useTransform, useScroll } from "framer-motion";
import { useEffect, useState } from "react";
import { IoIosPin } from "react-icons/io";
import { IoChevronDownSharp } from "react-icons/io5";

const Hero = () => {
    const { scrollY } = useScroll();
    const [height, setHeight] = useState(window.innerHeight - 64);
    const [width, setWidth] = useState(window.innerWidth);

    useEffect(() => {
        const handleResize = () => {
            setHeight(window.innerHeight - 64);
            setWidth(window.innerWidth);
        };

        window.addEventListener("resize", handleResize);

        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []);

    const y = useTransform(scrollY, [0, height], ["25%", "2rem"]);
    const textY = useTransform(scrollY, [0, height], ["100%", "100%"]);
    const opacity = useTransform(scrollY, [0, height], [1, 0]);
    const fontSize = useTransform(
        scrollY,
        [0, height],
        [`${width / 100}px`, "3rem"]
    );

    return (
        <div id="home" className="relative" style={{ height: "100dvh" }}>
            <motion.div
                style={{
                    position: "fixed",
                    top: y,
                    left: "50%",
                    transform: "translateX(-50%) translateY(-50%)",
                    width: "fit-content",
                    fontSize: fontSize,
                    zIndex: 30,
                }}
            >
                <motion.button
                    onClick={() =>
                        window.scrollTo({ top: 0, behavior: "smooth" })
                    }
                    className="w-full text-nowrap uppercase text-primary"
                    style={{
                        fontSize: "inherit",
                    }}
                >
                    Ben<span className="font-sans"> </span>Kamin
                </motion.button>
                <motion.div
                    style={{ opacity }}
                    className="absolute top-[80%] inset-0 text-base text-center text-primary space-x-12 sm:space-x-24 text-nowrap w-screen left-1/2 transform -translate-x-1/2 flex justify-center items-center"
                >
                    <span>Full Stack Software Engineer</span>
                    <div className="space-x-1 flex justify-center items-center">
                        <IoIosPin />
                        <span>San Francisco, CA</span>
                    </div>
                </motion.div>
            </motion.div>
            <motion.button
                style={{
                    position: "fixed",
                    top: textY,
                    left: "50%",
                    transform: "translateX(-50%) translateY(-150%)",
                    zIndex: 10,
                    opacity: useTransform(
                        scrollY,
                        [0, window.innerHeight * 0.1],
                        [1, 0]
                    ),
                }}
                initial={{ opacity: 1 }}
                transition={{ duration: 0.3 }}
                className="flex gap-2 group justify-center items-center text-primary z-10"
                onClick={() => {
                    window.scrollTo({
                        top: height,
                        behavior: "smooth",
                    });
                }}
            >
                Scroll Down <IoChevronDownSharp className="" />
            </motion.button>
        </div>
    );
};

export default Hero;
