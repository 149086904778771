import { createSlice } from "@reduxjs/toolkit";

interface NavMenu {
    firstRender: boolean;
    firstRenderTransition: boolean;
    numPages: number;
}

const initialState: NavMenu = {
    firstRender: true,
    numPages: 0,
    firstRenderTransition: true,
};

const Slice = createSlice({
    name: "value",
    initialState,
    reducers: {
        setIsFirstRender: (state, action) => {
            state.firstRender = action.payload;
        },
        addPage: (state) => {
            state.numPages += 1;
        },
        setIsFirstRenderTransition: (state, action) => {
            state.firstRenderTransition = action.payload;
        }
    },
});

export const { setIsFirstRender, addPage, setIsFirstRenderTransition } = Slice.actions;
export default Slice.reducer;
export type { NavMenu };
