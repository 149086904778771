import { motion } from "framer-motion";
import { ComponentType } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../utils/state/store";
import { downloadResume } from "../../utils/general";
import { LiaDownloadSolid } from "react-icons/lia";

const PageTransition = (OriginalComponent: ComponentType) => {
    const { open } = useSelector((state: RootState) => state.navmenu);
    const { firstRender } = useSelector((state: RootState) => state.renders);

    const variants = {
        open: {
            clipPath: `circle(150% at 2rem 2rem)`,
            transition: {
                type: "spring",
                stiffness: 45,
                restDelta: 2,
            },
        },
        closed: {
            clipPath: "circle(0% at 2rem 2rem)",
            transition: {
                type: "spring",
                stiffness: 300,
                damping: 30,
            },
        },
    };

    const blurVariants = {
        open: {
            backdropFilter: "blur(0px)",
            display: 'none',
            transition: {
                type: "spring",
                stiffness: 45,
                restDelta: 2,
            },
        },
        closed: {
            backdropFilter: "blur(10px)",
            transition: {
                type: "spring",
                stiffness: 300,
                damping: 30,
            },
        },
    }

    return () => (
        <>
            <OriginalComponent />

            {/* Blur in */}
            <motion.div
                className={`fixed top-0 left-0 w-full h-full z-40 overflow-clip pointer-events-none`}
                initial={!open && "closed"}
                animate={!open && "open"}
                exit="open"
                variants={blurVariants}
                style={{ zIndex: 20 }}
            />

            {/* slide in */}
            {!firstRender && (
                <motion.div
                    className={`fixed top-0 left-0 ${
                        !open ? "bg-primary" : "pointer-events-none"
                    } origin-top overflow-clip w-full h-full`}
                    initial={!open ? "open" : false}
                    animate={!open ? "closed" : false}
                    exit={"closed"}
                    variants={variants}
                    style={{ zIndex: 51 }}
                >
                    <div className="h-16 flex items-center justify-center px-8 relative text-background">
                        <button
                            onClick={downloadResume}
                            className={`top-8 right-8 fixed -translate-x-1/2 -translate-y-1/2 w-fit h-fit group transition-all`}
                        >
                            <LiaDownloadSolid className="group-active:translate-y-1 transition-all" />
                        </button>
                        <h1
                            className="text-center w-fit uppercase"
                            style={{ fontSize: "3rem" }}
                        >
                            Ben<span className="font-sans"> </span>Kamin
                        </h1>
                    </div>
                </motion.div>
            )}

            {/* slide out */}
            {!open && (
                <motion.div
                    className={`fixed top-0 left-0 w-full h-full bg-primary origin-top z-40 overflow-clip`}
                    initial={!open && "closed"}
                    animate={!open && "closed"}
                    exit="open"
                    variants={variants}
                    style={{ zIndex: 51 }}
                >
                    <div className="h-16 flex items-center justify-center px-8 relative text-background">
                        <button
                            onClick={downloadResume}
                            className={`top-8 right-8 fixed -translate-x-1/2 -translate-y-1/2 w-fit h-fit group transition-all`}
                        >
                            <LiaDownloadSolid className="group-active:translate-y-1 transition-all" />
                        </button>
                        <h1
                            className="text-center w-fit uppercase"
                            style={{ fontSize: "3rem" }}
                        >
                            Ben<span className="font-sans"> </span>Kamin
                        </h1>
                    </div>
                </motion.div>
            )}
        </>
    );
};

export default PageTransition;
