import { useScroll, useTransform, motion } from "framer-motion";
import React from "react";
import { IoChevronDownSharp } from "react-icons/io5";
import { SiJavascript, SiHtml5, SiCss3 } from "react-icons/si";
import { Link } from "react-router-dom";
import CaretTopRightIcon from "../../components/icons/TRCaret";

const NoiseGenerator = () => {
    const { scrollYProgress } = useScroll();

    const opacity = useTransform(scrollYProgress, [0, 0.05], [1, 0]);

    const languages: { [key: string]: { [key: string]: JSX.Element } } = {
        Frontend: {
            Javascript: <SiJavascript />,
            HTML: <SiHtml5 />,
            CSS: <SiCss3 />,
        },
    };

    return (
        <div className="">
            <div className="w-full h-screen flex justify-center items-center">
                <div className="w-auto divide-y-[1px] divide-primary flex flex-col justify-center items-center">
                    <h1 className="text-4xl px-4 text-nowrap w-min">
                        Noise Generator
                    </h1>
                    <div className="flex flex-center items-center gap-4 w-full pt-1">
                        <div className="w-full h-full flex justify-center items-center gap-4">
                            {Object.keys(languages).map((area: string) => (
                                <React.Fragment key={area}>
                                    {Object.entries(languages[area]).map(
                                        ([language, icon]) => (
                                            <React.Fragment key={language}>
                                                {icon}
                                            </React.Fragment>
                                        )
                                    )}
                                </React.Fragment>
                            ))}
                        </div>
                    </div>
                </div>
                <motion.p
                    style={{ opacity }}
                    className="flex gap-2 group justify-center items-center text-primary z-10 fixed bottom-1"
                >
                    Scroll Down <IoChevronDownSharp />
                </motion.p>
            </div>

            <p className="text-center pb-4">Live Demo</p>

            <div className="flex justify-center items-center flex-col space-y-4 mb-24">
                <iframe
                    title="Search Engine"
                    src="https://noise-generator.tiiny.site/"
                    width="100%"
                    height="100%"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen
                    className="aspect-square w-3/4"
                    sandbox="allow-same-origin allow-scripts allow-popups allow-forms"
                />

                <Link
                    to={"https://noise-generator.tiiny.site/"}
                    target="_blank"
                    className="mt-4 group relative px-2"
                >
                    Noise Generator
                    <CaretTopRightIcon className="group-hover:opacity-100 opacity-0 absolute top-0 right-0 w-2 h-2" />
                </Link>
            </div>
        </div>
    );
};

export default NoiseGenerator;
export const thumbnail = {
    url: "/assets/images/projects/noise_generator_2.png",
    title: "Noise Generator",
    link: "/noise-generator",
    hosted: true,
};
