import React, { useEffect, useState } from "react";
import { motion } from "framer-motion";
import { Link, useLocation } from "react-router-dom";
import { IoChevronForwardSharp } from "react-icons/io5";
import { downloadResume, toLink } from "../../utils/general";
import { useSelector } from "react-redux";
import { RootState } from "../../utils/state/store";
import { LiaDownloadSolid } from "react-icons/lia";
import { MdOutlineClose } from "react-icons/md";
import { setIsNavOpen } from "../../utils/state/slices/navmenu";
import { useDispatch } from "react-redux";
import { SiGithub, SiLinkedin } from "react-icons/si";
import { GrMail } from "react-icons/gr";

interface NavMenuProps {
    open: boolean;
    onClose: () => void;
}

const NavMenu: React.FC<NavMenuProps> = ({ open, onClose }) => {
    const variants = {
        open: {
            clipPath: `circle(150% at 2rem 2rem)`,
            transition: {
                type: "spring",
                stiffness: 45,
                restDelta: 2,
            },
        },
        closed: {
            clipPath: "circle(0% at 2rem 2rem)",
            transition: {
                type: "spring",
                stiffness: 300,
                damping: 30,
            },
        },
    };

    const { projects, experience } = useSelector(
        (state: RootState) => state.pages
    );

    const navOptions = [
        {
            name: "Experience",
            link: "experience",
            external: experience,
        },
        {
            name: "Projects",
            link: "projects",
            external: projects,
        },
        { name: "About", link: "about", external: false },
    ];

    const [openedAccordion, setOpenedAccordion] = useState<number>(-1);
    const location = useLocation();
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(setIsNavOpen(false));
        setOpenedAccordion(-1);
    }, [location.pathname, dispatch]);

    useEffect(() => {
        document.body.style.overflow = open ? "hidden" : "auto";
        return () => {
            document.body.style.overflow = "auto";
        };
    }, [open]);

    return (
        <motion.div
            className="fixed top-0 left-0 w-full h-full bg-primary z-50 justify-end flex flex-col text-background"
            initial={false}
            animate={open ? "open" : "closed"}
            variants={variants}
        >
            <div className="relative flex justify-end flex-col h-full p-12">
                <button
                    onClick={downloadResume}
                    className={`top-8 right-8 absolute -translate-x-1/2 -translate-y-1/2 w-fit h-fit group transition-all text-background`}
                    style={{ zIndex: 500 }}
                >
                    <LiaDownloadSolid className="group-active:translate-y-1 transition-all" />
                </button>
                <button
                    onClick={onClose}
                    style={{ zIndex: 9999 }}
                    className={`top-8 left-8 fixed -translate-x-1/2 -translate-y-1/2 w-fit h-fit text-background`}
                >
                    <div
                        className={`transition-all ${
                            open ? "rotate-0" : "rotate-180"
                        }`}
                    >
                        <MdOutlineClose />
                    </div>
                </button>
                {window.location.pathname === "/" ? (
                    <button
                        className="uppercase absolute -top-1 inset-0 w-full h-fit text-center flex justify-center items-center pointer-events-none"
                        style={{ fontSize: "3rem" }}
                    >
                        <h1
                            className="pointer-events-auto"
                            onClick={() => {
                                window.scrollTo({ top: 0 });
                                onClose();
                                setOpenedAccordion(-1);
                            }}
                        >
                            Ben<span className="font-sans"> </span>Kamin
                        </h1>
                    </button>
                ) : (
                    <div className="uppercase absolute -top-1 inset-0 w-full h-fit text-center flex justify-center items-center pointer-events-none">
                        <Link
                            className="pointer-events-auto"
                            style={{ fontSize: "3rem" }}
                            to={"/"}
                        >
                            Ben<span className="font-sans"> </span>Kamin
                        </Link>
                    </div>
                )}

                <motion.div
                    initial={{ opacity: 0, y: -20 }}
                    animate={
                        open ? { opacity: 1, y: 0 } : { opacity: 0, y: -20 }
                    }
                    transition={{
                        duration: 0.3,
                        delay: (open ? 0.5 : 0) + 0 * 0.1,
                    }}
                    className="flex gap-4 mb-4"
                >
                    <Link target="_blank" to={"https://www.linkedin.com/in/benjamin-kamin/"}>
                        <SiLinkedin className="text-4xl" />
                    </Link>
                    <Link target="_blank" to={"https://github.com/BenKamin03"}>
                        <SiGithub className="text-4xl" />
                    </Link>
                    <Link target="_blank" to="mailto:benjamin.kamin.81@gmail.com">
                        <GrMail className="text-4xl" />
                    </Link>
                </motion.div>

                {navOptions.map((option, index) => (
                    <motion.div
                        key={`nav-option-${index}`} // Unique key for each option
                        initial={{ opacity: 0, y: -20 }}
                        animate={
                            open ? { opacity: 1, y: 0 } : { opacity: 0, y: -20 }
                        }
                        transition={{
                            duration: 0.3,
                            delay: (open ? 0.5 : 0) + index * 0.1 + .1,
                        }}
                    >
                        <React.Fragment key={`nav-fragment-${index}`}>
                            <div
                                key={`separator-${index}`}
                                className="w-full h-[.0625rem] bg-background"
                            />
                            <div className="w-full text-2xl sm:text-3xl px-4 flex justify-between items-center">
                                {option.external ? (
                                    <Accordion
                                        isOpen={openedAccordion === index}
                                        setIsOpen={(value) =>
                                            setOpenedAccordion(
                                                value ? index : -1
                                            )
                                        }
                                        title={
                                            <div className="uppercase group w-full text-left flex gap-4 items-center justify-between">
                                                {window.location.pathname ===
                                                    "/" ||
                                                window.location.pathname ===
                                                    toLink(option.link) ? (
                                                    <button
                                                        onClick={() => {
                                                            let element =
                                                                document.getElementById(
                                                                    toLink(
                                                                        option.name
                                                                    )
                                                                );
                                                            if (
                                                                !element &&
                                                                option.external
                                                            ) {
                                                                element =
                                                                    document.getElementById(
                                                                        toLink(
                                                                            option
                                                                                .external[0]
                                                                        )
                                                                    );

                                                                element?.scrollIntoView(
                                                                    {
                                                                        block: "center",
                                                                    }
                                                                );
                                                                onClose();
                                                                setOpenedAccordion(
                                                                    -1
                                                                );
                                                            } else {
                                                                if (element) {
                                                                    element.scrollIntoView(
                                                                        {
                                                                            block: "start",
                                                                        }
                                                                    );
                                                                    onClose();
                                                                    setOpenedAccordion(
                                                                        -1
                                                                    );
                                                                }
                                                            }
                                                        }}
                                                        className="py-4 uppercase group w-full text-left flex gap-4 items-center"
                                                    >
                                                        {`(${(index + 1)
                                                            .toString()
                                                            .padStart(
                                                                2,
                                                                "0"
                                                            )})`}{" "}
                                                        <div className="group-hover:translate-x-2 transition-all">
                                                            {option.name}
                                                        </div>
                                                    </button>
                                                ) : (
                                                    <Link
                                                        className="py-4 uppercase group w-full text-left flex gap-4 items-center"
                                                        to={"/"}
                                                    >
                                                        {`(${(index + 1)
                                                            .toString()
                                                            .padStart(
                                                                2,
                                                                "0"
                                                            )})`}{" "}
                                                        <div className="group-hover:translate-x-2 transition-all">
                                                            {option.name}
                                                        </div>
                                                    </Link>
                                                )}
                                            </div>
                                        }
                                    >
                                        {Array.isArray(option.external) &&
                                            option.external.map(
                                                (external, externalIndex) => (
                                                    <div
                                                        key={`external-${index}-${externalIndex}`}
                                                    >
                                                        {window.location
                                                            .pathname !==
                                                        `/${toLink(
                                                            external
                                                        )}` ? (
                                                            <Link
                                                                className="w-fit"
                                                                to={toLink(
                                                                    external
                                                                )}
                                                            >
                                                                {external}
                                                            </Link>
                                                        ) : (
                                                            <p>{external}</p>
                                                        )}
                                                    </div>
                                                )
                                            )}
                                    </Accordion>
                                ) : window.location.pathname === "/" ||
                                  window.location.pathname ===
                                      toLink(option.link) ? (
                                    <button
                                        onClick={() => {
                                            const element =
                                                document.getElementById(
                                                    Array.isArray(
                                                        option.external
                                                    )
                                                        ? toLink(
                                                              option.external[0]
                                                          )
                                                        : toLink(option.link)
                                                );

                                            if (element) {
                                                element.scrollIntoView({
                                                    block: "center",
                                                });
                                                onClose();
                                                setOpenedAccordion(-1);
                                            }
                                        }}
                                        className="py-4 uppercase group w-full text-left flex gap-4 items-center"
                                    >
                                        {`(${(index + 1)
                                            .toString()
                                            .padStart(2, "0")})`}{" "}
                                        <div className="group-hover:translate-x-2 transition-all">
                                            {option.name}
                                        </div>
                                    </button>
                                ) : (
                                    <Link
                                        className="py-4 uppercase group w-full text-left flex gap-4 items-center"
                                        to={"/"}
                                    >
                                        {`(${(index + 1)
                                            .toString()
                                            .padStart(2, "0")})`}{" "}
                                        <div className="group-hover:translate-x-2 transition-all">
                                            {option.name}
                                        </div>
                                    </Link>
                                )}
                            </div>
                            {index === navOptions.length - 1 && (
                                <div className="w-full h-[.0625rem] bg-background" />
                            )}
                        </React.Fragment>
                    </motion.div>
                ))}
            </div>
        </motion.div>
    );
};

const Accordion = ({
    title,
    children,
    isOpen,
    setIsOpen,
}: {
    title: React.ReactElement;
    children: React.ReactNode;
    isOpen: boolean;
    setIsOpen: (value: boolean) => void;
}) => {
    const variants = {
        open: { height: "auto", opacity: 1, transition: { duration: 0.3 } },
        closed: { height: 0, opacity: 0, transition: { duration: 0.3 } },
    };

    return (
        <div className="accordion w-full">
            <div className="accordion-header w-full text-left bg-secondary text-background flex justify-center items-center">
                {title}
                <button
                    onClick={() => setIsOpen(!isOpen)}
                    className="accordion-header w-full flex-1 text-left bg-secondary text-background flex justify-center items-center"
                >
                    <IoChevronForwardSharp
                        className={`transition-transform transform ${
                            isOpen ? "rotate-90" : ""
                        }`}
                    />
                </button>
            </div>
            <motion.div
                initial={false}
                animate={isOpen ? "open" : "closed"}
                variants={variants}
                className="accordion-content overflow-hidden"
            >
                <div className="p-4 flex flex-col gap-2 justify-end items-end w-full">
                    {children}
                </div>
            </motion.div>
        </div>
    );
};

export default NavMenu;
