import React from "react";
import HorizontalScrollCarousel from "../../../../components/carousels/HorizontalScrollCarousel";
import { importAll } from "../../../../utils/general";


const Projects = () => {
    const projectsObject = importAll(
        require.context("../../../../pages/projects", false, /\.tsx$/)
    );

    const projects = Object.keys(projectsObject).map((experience) => {
        const Project = projectsObject[experience];
        return {
            ...Project.thumbnail,
            id: experience,
        };
    });

    return (
        <div id="projects" className="">
            <HorizontalScrollCarousel
                cards={projects}
                label={
                    <div className="flex items-center font-normal space-x-4 w-full">
                        <h1 className="text-4xl">Projects</h1>
                    </div>
                }
            />
        </div>
    );
};

export default Projects;
