import Experience from "./sections/experience/Experience";
import Hero from "./sections/hero/Hero";
import Projects from "./sections/projects/Projects";
import About from "./sections/about/About";
import Languages from "./sections/languages/Languages";

const Home = () => {
    return (
        <div className="overflow-x-clip w-screen">
            <Hero />
            <Experience />
            <Languages />
            <Projects />
            <About />
        </div>
    );
};

export default Home;
